import store from "../../store/index";
import lodash from "lodash";
import AddressFilterService from "../../addressfilter.service";
// import MarkerClusterer from "@googlemaps/markerclustererplus";
import MarkerClusterer from "./pie-markercluster";
import ColorService from "../../colors/color.service";
import i18nDiclinaService from "../../diclina_i18n";

import Vue from "vue";
import PieClusterMarker from "../../../../view/pages/addresses/maps/PieClusterMarker";

const zoomThreshold = 17;

let marker = [];
let clusterMarkerer = null;
let google = null;
let map = null;
let header = [];

const markerMapView = {
  name: "marker",
  initMarker: async () => {
    const locations = await store.getters.addresses;

    if (google && locations) {
      const zoom = map.getZoom();
      const isSingleStatusFilter = store.getters.statusGroupFilterCount() === 1;
      const firstStatusGroupKey = store.getters.firstStatusGroupKey();
      const status = store.getters.status.meta.overall;

      locations.forEach(location => {
        if (AddressFilterService.filterAddress(location)) {
          store.dispatch("addVisibleAddress", location);

          const point = new google.maps.LatLng(
            location.point.lat,
            location.point.lng
          );

          const label = isSingleStatusFilter
            ? location.status[firstStatusGroupKey]
            : "";

          let styleName = isSingleStatusFilter
            ? status[firstStatusGroupKey].styles[
                "status_" + location.status[firstStatusGroupKey]
              ].colorstatus
            : "light-blue";

          styleName = styleName ? styleName : "light-blue";

          const newMarker = createMarker(
            point,
            label,
            createInfoWindow(location),
            styleName,
            map
          );

          newMarker.setMap(map);
          newMarker.setVisible(zoom > zoomThreshold);

          marker.push(newMarker);
        }
      });

      if (zoom <= zoomThreshold) {
        clusterMarkerer = await createMarkerClusterer();
      }
    }
  },
  resetMarker: async () => {
    for (let gmarker of marker) {
      gmarker.setMap(null);
      gmarker = null;
    }

    if (clusterMarkerer) {
      clusterMarkerer.clearMarkers();
    }
    clusterMarkerer = null;

    marker.length = 0;

    await store.dispatch("clearVisibleAddresses").then(() => {});
  },
  setGoogle: googleObj => (google = googleObj),
  setMap: mapObj => (map = mapObj),
  setHeader: headerObj => (header = headerObj)
};

const createMarker = (position, title, infoWindow, style, map = null) => {
  let contentString = infoWindow;
  const icon = require("../../../../../public/media/marker/Marker_" +
    style +
    ".png");

  let marker = new google.maps.Marker({
    position,
    map,
    shadow: null,
    icon,
    shape: null,
    title,
    zIndex: Math.round(position.lat() * -100000) << 5
  });

  let infowindow = new google.maps.InfoWindow({
    size: new google.maps.Size(150, 50)
  });

  google.maps.event.addListener(marker, "click", function() {
    infowindow.setContent(contentString);
    infowindow.open(map, marker);
  });

  return marker;
};

const createInfoWindow = location => {
  let infoWindow = "<table class='table table-borderless'>";

  for (let i = 0; i < header.length; i++) {
    if (!header[i].value.startsWith("status.")) {
      infoWindow +=
          "<tr><th>" +
          header[i].text +
          "</th><td>" +
          (lodash.get(location, header[i].value) || " - ") +
          "</td></tr>";
    }
  }

  const statusGroups = store.getters.statusGroups;
  for (const statusGroup of statusGroups) {
    infoWindow +=
        "<tr><th>" +
        statusGroup.text +
        "</th><td>" +
        i18nDiclinaService.getDiclinaStatusName(statusGroup.value.key, location.status[statusGroup.value.key]) +
        "</td></tr>";
  }

  infoWindow += "</table>";

  return infoWindow;
};

const createMarkerClusterer = async () => {
  let opt = {
    gridSize: 100,
    styles: [
      { height: 35, width: 35 },
      { height: 40, width: 40 },
      { height: 45, width: 45 },
      { height: 50, width: 50 },
      { height: 60, width: 60 }
    ],
    status: [],
    callbackOnAdd: createPieClusterMarker
  };

  google.load("visualization", "1.0", { packages: ["corechart", "piechart"] });
  window.google = google;
  return new MarkerClusterer(map, marker, opt);
};

const createPieClusterMarker = (markers, size) => {
  if (markers.length > 1) {
    let ComponentClass = Vue.extend(PieClusterMarker);

    let sections = [];

    if (store.getters.statusGroupFilterCount() === 1) {
      const firstStatusGroupKey = store.getters.firstStatusGroupKey();

      const labels =
        store.getters.status.meta.overall[firstStatusGroupKey].i18n.de_DE;
      const styles =
        store.getters.status.meta.overall[firstStatusGroupKey].styles;

      // eslint-disable-next-line no-unused-vars
      for (const [key, unused] of Object.entries(labels)) {
        const value =
          (markers.filter(obj => obj.title === key.replace("status_", ""))
            .length /
            markers.length) *
          100;

        const label =
          i18nDiclinaService.getDiclinaStatusGroupName(firstStatusGroupKey) +
          ": " +
          i18nDiclinaService.getDiclinaStatusName(
            firstStatusGroupKey,
            key.replace("status_", "")
          );

        sections.push({
          label,
          value,
          color: ColorService.convertHexToRGBA(
            ColorService.getColorByName(styles[key].colorstatus).dark,
            0.6
          )
        });
      }
    } else {
      sections = [
        {
          label: "Gesamt",
          value: 100,
          color: ColorService.convertHexToRGBA(
            ColorService.getColorByName("light-blue").dark,
            0.6
          )
        }
      ];
    }

    let instance = new ComponentClass({
      propsData: { count: markers.length, size, sections }
    });
    instance.$mount();

    return instance.$el;
  } else {
    return document.createElement("DIV");
  }
};

export default markerMapView;
