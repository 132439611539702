// DICLINA Translations
const locale = {
  STATUSGROUP: {
    buildconn: {
      de: "Hausanschluss",
      STATUS: {
        open: {
          de: "Noch nicht beauftragt"
        },
        opened: {
          de: "In Auftrag gegeben"
        },
        pending: {
          de: "In Bau"
        },
        accepted: {
          de: "Fertig gestellt"
        }
      }
    },
    gnv: {
      de: "GNV",
      STATUS: {
        open: {
          de: "Nicht angeboten"
        },
        opened: {
          de: "Angeboten"
        },
        accepted: {
          de: "Abgeschlossen"
        },
        rejected: {
          de: "Abgelehnt"
        }
      }
    },
    internet: {
      de: "Hatte zuvor Internet",
      STATUS: {
        open: {
          de: "Hatte Internet"
        },
        accepted: {
          de: "Hatte kein Internet"
        }
      }
    },
    ne4: {
      de: "NE4 Bau",
      STATUS: {
        open: {
          de: "Nicht beauftragt"
        },
        opened: {
          de: "Teiweise fertiggestellt"
        },
        progress: {
          de: "In Bau"
        },
        commissioned: {
          de: "Beauftragt"
        },
        completed: {
          de: "Fertiggestellt"
        }
      }
    }
  }
};

export default locale;
