<template>
  <vc-donut
    background="rgba(255, 255, 255, 0.8)"
    foreground="transparent"
    :size="size"
    unit="px"
    :has-legend="false"
    :thickness="40"
    :sections="sections"
    :total="100"
    :start-angle="0"
    :auto-adjust-text-size="false"
  >
    <span style="margin: 0; font-size: 1.0rem;">{{ count }}</span>
  </vc-donut>
</template>

<script>
export default {
  name: "PieClusterMarker",
  props: {
    count: {
      default: 1
    },
    size: {
      default: 30
    },
    sections: {
      default: () => []
    }
  }
};
</script>

<style scoped></style>
