<template>
  <div>
    <!--begin::map-->
    <div class="row">
      <div class="col-md-3 col-12 mb-md-0 mb-8 pr-lg-0 pr-4">
        <MapFilter
          :map-views="mapViews"
          @changeMapView="currentMapView = arguments[0]"
          @refreshMap="setLoadingMapView(arguments[0])"
        />
      </div>
      <div class="col-md-9 col-12 pl-lg-0 pl-4">
        <GoogleMap
          :headers="dataTable.headers"
          @loadingMapView="setLoadingMapView(arguments[0])"
          ref="map"
        />
      </div>
    </div>
    <!--end::map-->

    <!-- begin::datatable -->
    <div class="row mt-10">
      <div class="col-12">
        <AddressDataTable
          :headers="dataTable.headers"
          item-key="address.id"
          class="elevation-1"
        />
      </div>
    </div>
    <!-- end::datatable -->

    <!-- begin::loadingMapView -->
    <b-modal
      id="loading-map-view-modal"
      cancel-disabled
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      @shown="refreshMap"
    >
      <div class="text-center">
        <v-progress-linear value="15"></v-progress-linear>
        <span>
          Bitte warten.<br />
          Die Karte wird aktualisiert. Dies kann eine Weile dauern.
        </span>
      </div>
    </b-modal>
    <!-- end::loadingMapView -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import GoogleMap from "@/view/content/maps/GoogleMap";

import MapFilter from "@/view/pages/addresses/filter/MapFilter";
import AddressDataTable from "@/view/pages/addresses/datatable/AddressDataTable";

export default {
  name: "addresses",
  components: {
    AddressDataTable,
    MapFilter,
    GoogleMap
  },
  data() {
    return {
      currentMapView: "marker",
      mapViews: [
        { text: "Marker", value: "marker" },
        {
          text: "Heatmap (bald verfügbar)",
          value: "heatmap",
          disabled: true
        }
      ],
      status: [],
      selectedStatus: [],
      selectedAddresses: [],
      locationsCount: 0,
      loadingMapView: false
    };
  },
  computed: {
    mapMarker() {
      return this.selectedAddresses.length > 0
        ? this.selectedAddresses
        : this.locations;
    },
    mapStatus() {
      let mapStatus = {};

      for (let i = 0; i < this.status.length; i++) {
        mapStatus[this.status[i].value] = this.status[i];
      }

      return mapStatus;
    },
    dataTable() {
      let headers = [
        {
          text: "Straße",
          align: "left",
          value: "address.street"
        },
        { text: "Hausnummer", value: "address.houseNumber" },
        { text: "PLZ", value: "address.zipCode" },
        { text: "Anzahl Haushalte", value: "info.amountHouseHolds" },
        { text: "Eigentümer", value: "address.lastName" }
      ];

      const statusGroups = this.$store.getters.statusGroups;
      for (const statusGroup of statusGroups) {
        headers.push({
          text: statusGroup.text,
          value: "status." + statusGroup.value.key
        });
      }

      return {
        headers,
        items: this.locations,
        selectedAddresses: []
      };
    }
  },
  methods: {
    loadStatus() {
      this.$store.dispatch("updateStatus");
    },
    async loadAddresses() {
      await this.$store.dispatch("updateAddresses", true);
    },
    setLoadingMapView(loadingMapView) {
      this.loadingMapView = loadingMapView;

      if (loadingMapView) {
        this.$bvModal.show("loading-map-view-modal");
      } else {
        this.$bvModal.hide("loading-map-view-modal");
      }
    },
    refreshMap() {
      this.$refs.map.refreshMap();
    }
  },
  async beforeCreate() {
    await this.$store.dispatch(SET_BREADCRUMB, [
      { title: "tktVivax", subTitle: "Vertriebssteuerungstool" }
    ]);

    this.loadStatus();
  }
};
</script>
