<template>
  <div class="card card-custom h-100 shadow zindex-1 rounded-right-0 overflow-auto" style="max-height: 80vh">
    <div class="card-header container-fluid border-0">
      <b-row class="justify-content-between align-items-center">
        <b-col cols="auto">
          <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Adressfilter
          </span>
          <!--<span class="text-muted mt-3 font-weight-bold font-size-sm">
            Welche Adressen sollen angezeigt werden?
          </span>-->
        </h3></b-col>
        <b-col cols="auto">
          <!-- not a fan of this button being colored, would like a grey -->
          <b-button variant="outline-info" class="mt-3" @click="saveFilter">
            <span class="fas fa-sync-alt mr-1"></span>
            Filter Anwenden
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 col-sm-4 col-12 mb-sm-0" style="display: none">
          <b-form-group label="Map Ansicht">
            <b-form-radio-group
              class="w-100"
              v-model="currentMapView"
              :options="mapViews"
              buttons
              button-variant="outline-secondary"
              stacked
            />
          </b-form-group>
        </div>
        <div
          v-for="statusGroup in statusGroups"
          :key="statusGroup.value.key"
          class="col-md-12 col-sm col-12 mb-md-8 mb-0"
        >
          <b-form-group
            :label="getStatusGroupName(statusGroup.value.key)"
            label-class="h5 pb-0 mb-3 border-bottom border-bottom-secondary"
            class="mb-0"
          >
            <b-form-checkbox-group switches stacked size="md">
              <b-form-checkbox
                v-for="(option, index) in statusGroup.value.statusGroup.i18n
                  .de_DE"
                :key="index"
                :value="index"
                v-model="statusFilter[statusGroup.value.key]"
                class="my-2 noselect child-label-w-100"
              >
                <b-row align-h="between">
                  <b-col class="py-0" cols="9">{{
                    getStatusName(statusGroup.value.key, index)
                  }}</b-col>
                  <b-col class="py-0" cols="auto"
                    ><img
                      :src="
                        '/media/marker/Marker_' +
                          statusGroup.value.statusGroup.styles[index]
                            .colorstatus +
                          '.png'
                      "
                      :alt="
                        statusGroup.value.statusGroup.styles[index].colorstatus
                      "
                  /></b-col>
                </b-row>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18nDiclinaService from "../../../../core/services/diclina_i18n";

export default {
  name: "MapFilter",
  props: ["mapViews"],
  data() {
    return {
      currentMapView: "marker",
      activeStatusArray: [],
      selectedStatusGroup: null,
      item: null,
      statusFilter: {}
    };
  },
  computed: {
    loadedAddressesCount() {
      return this.$store.getters.loadedAddressesCount;
    },
    statusGroups() {
      return this.$store.getters.statusGroups;
    }
  },
  methods: {
    changeMapView(value) {
      this.$emit("changeMapView", value);
    },
    refreshMap() {
      this.$emit("refreshMap", true);
    },
    saveFilter() {
      this.$store.dispatch(
        "setStatusFilter",
        JSON.parse(JSON.stringify(this.statusFilter))
      );

      this.refreshMap();
    },
    getStatusGroupName(statusGroup) {
      return i18nDiclinaService.getDiclinaStatusGroupName(statusGroup);
    },
    getStatusName(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusName(
        statusGroup,
        status.replace("status_", "")
      );
    }
  }
};
</script>

<style scoped></style>
