// Your personal API key.
// Get it here: https://console.cloud.google.com/google/maps-apis
const API_KEY = "AIzaSyCPgKJ0GrwQW3oDFCh8-DyYeIVQK8upAdw";
const CALLBACK_NAME = "initMap";

let initialized = !!window.google;
let resolveInitPromise;
let rejectInitPromise;

// This promise handles the initialization
// status of the google maps script.
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

const mapStyles = [
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off"
      }
    ]
  }
];

const gmap = {
  mapOptions: {
    styles: mapStyles
  },
  init() {
    // If Google Maps already is initialized
    // the `initPromise` should get resolved
    // eventually.
    if (initialized) return initPromise;

    initialized = true;
    // The callback function is called by
    // the Google Maps script if it is
    // successfully loaded.
    window[CALLBACK_NAME] = () => resolveInitPromise(window.google);

    // We inject a new script tag into
    // the `<head>` of our HTML to load
    // the Google Maps script.
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=${CALLBACK_NAME}&libraries=visualization`;
    script.onerror = rejectInitPromise;
    document.querySelector("head").appendChild(script);

    const otherScript = document.createElement("script");
    otherScript.async = true;
    otherScript.defer = true;
    otherScript.src = `https://www.google.com/jsapi`;
    document.querySelector("head").appendChild(otherScript);

    return initPromise;
  },
  getMarkerIcon(style) {
    const scale = 1.2;

    return {
      path:
        // eslint-disable-next-line max-len
        "M9.82829464,16.6565893 C7.02541569,15.7427556 5,13.1079084 5,10 C5,6.13400675 8.13400675,3 12,3 C15.8659932,3 19,6.13400675 19,10 C19,13.1079084 16.9745843,15.7427556 14.1717054,16.6565893 L12,21 L9.82829464,16.6565893 Z M12,12 C13.1045695,12 14,11.1045695 14,10 C14,8.8954305 13.1045695,8 12,8 C10.8954305,8 10,8.8954305 10,10 C10,11.1045695 10.8954305,12 12,12 Z",
      fillColor: style.light,
      fillOpacity: 0.85,
      strokeColor: style.dark,
      strokeOpacity: 1.0,
      scale: scale,
      anchor: {
        x: 12 * scale,
        y: 20 * scale
      }
    };
  }
};

export default gmap;
