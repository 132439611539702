import locale from "../config/i18n/diclina";
import i18nService from "./i18n.service";

const lang = i18nService.getActiveLanguage();

const i18nDiclinaService = {
  getDiclinaStatusGroupName: statusGroup => {
    return locale.STATUSGROUP[statusGroup][lang];
  },
  getDiclinaStatusName: (statusGroup, status) => {
    return locale.STATUSGROUP[statusGroup].STATUS[status][lang];
  }
};

export default i18nDiclinaService;
