<template>
  <div>
    <v-card-title>
      Adressdaten
      <b-button class="ml-5" @click="exportToExcel">
        <i class="fas fa-file-excel"></i>
        Download Tabelle
        <span v-show="selectedAddresses.length > 0">
          (Nur Auswahl)
        </span>
      </b-button>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchValue"
        append-icon="mdi-magnify"
        label="Suchen"
        clearable
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      class="shadow-sm"
      v-model="selectedAddresses"
      :headers="headers"
      :items="items"
      :item-key="itemKey"
      :search="search"
      loading
      loading-text="Keine Adressen gefunden"
      multi-sort
    >
      <template
        v-for="(statusGroup, index) in statusGroups"
        v-slot:[getStatusGroupSlotName(statusGroup.value.key)]="{ item }"
      >
        <span :key="index">{{
          getStatusName(
            statusGroup.value.key,
            item.status[statusGroup.value.key]
          )
        }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import i18nDiclinaService from "../../../../core/services/diclina_i18n";

export default {
  name: "AddressDataTable",
  props: ["headers", "itemKey", "loading"],
  data() {
    return {
      search: "",
      searchValue: "",
      awaitingSearch: false,
      searchTimeout: null,
      selectedAddresses: []
    };
  },
  computed: {
    exportArray() {
      let exportArray = [];

      let itemArray =
        this.selectedAddresses.length > 0 ? this.selectedAddresses : this.items;

      for (let i = 0; i < itemArray.length; i++) {
        let currRow = itemArray[i];
        exportArray[i] = [];

        for (let j = 0; j < this.headers.length; j++) {
          let currHeader = this.headers[j];

          if(!currHeader.value.startsWith("status.")) {
            exportArray[i][currHeader.text] = this.lodash.get(
                currRow,
                currHeader.value
            );
          }
        }

        const statusGroups = this.$store.getters.statusGroups;
        for (const statusGroup of statusGroups) {
          exportArray[i][statusGroup.text] = i18nDiclinaService.getDiclinaStatusName(statusGroup.value.key, currRow.status[statusGroup.value.key]);
        }
      }

      return exportArray;
    },
    statusGroups() {
      return this.$store.getters.statusGroups;
    }
  },
  asyncComputed: {
    items: {
      async get() {
        return await this.$store.getters.visibleAddresses;
      },
      default: []
    }
  },
  methods: {
    exportToExcel() {
      let data = this.exportArray;

      json2excel({
        data,
        name: "Adressdaten",
        formatDate: "dd.mm.yyyy"
      });
    },
    setSearch(value) {
      this.search = value;
    },
    getStatusName(statusGroup, status) {
      return i18nDiclinaService.getDiclinaStatusName(statusGroup, status);
    },
    getStatusGroupSlotName(statusGroup) {
      return "item.status."+statusGroup;
    }
  },
  watch: {
    searchValue() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search = this.searchValue;
        this.awaitingSearch = false;
      }, 500); // 0.5 sec delay
    },
    selectedAddresses() {
      this.$emit("changedSelectedAddresses", this.selectedAddresses);
    }
  }
};
</script>

<style scoped></style>
