import store from "./store/index";

const AddressFilterService = {
  filterAddress: address => {
    return matchesFilter(address);
  }
};

// eslint-disable-next-line no-unused-vars
const matchesFilter = location => {
  return matchesStatusFilter(store.getters.filter, location);
};

const matchesStatusFilter = (filter, location) => {
  let statusFilter = filter.status;

  for (const [key, statusGroup] of Object.entries(statusFilter)) {
    if (statusGroup.length > 0) {
      if (!statusGroup.includes("status_" + location.status[key])) {
        return false;
      }
    }
  }

  return true;
};

export default AddressFilterService;
