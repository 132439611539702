<template>
  <div
    id="map_canvas"
    class="shadow-sm rounded-left-0"
    style="width:100%; height:80vh;"
  ></div>
</template>

<script>
import gmap from "@/core/services/gmap/gmap.service";
import ColorService from "@/core/services/colors/color.service";

import markerMapView from "../../../core/services/gmap/mapviews/marker";

const zoomThreshold = 17;

let google;

// eslint-disable-next-line no-unused-vars
let viewportChangedTimeout = null;
window.curentZoom = 25;

export default {
  name: "googleMap",
  props: ["headers"],
  data() {
    return {
      map: null,
      heatmaps: [],
      markerClusterer: [],
      activatedMapView: this.currentMapView
    };
  },
  asyncComputed: {
    locations: {
      async get() {
        return await this.$store.getters.addresses;
      },
      default: []
    }
  },
  methods: {
    startRefreshMap() {
      this.$emit("loadingMapView", true);
    },
    refreshMap() {
      this.toggleMarkerMapView();
    },
    isStatusActive(statusId) {
      return this.selectedStatus.includes(statusId);
    },
    async toggleMarkerMapView() {
      const mapView = markerMapView;

      mapView.setGoogle(google);
      mapView.setMap(this.map);
      mapView.setHeader(this.headers);
      // mapView.resetLayers();
      // await mapView.init();
      // mapView.toggleOn();

      await mapView.resetMarker();
      await mapView.initMarker();

      this.$emit("loadingMapView", false);
    },
    toggleHeatmaps(on) {
      if (on) {
        this.createHeatmaps();
      }

      if (this.heatmaps.length > 0) {
        // eslint-disable-next-line no-unused-vars
        for (const [currStatusId, currStatus] of Object.entries(this.status)) {
          // for (let i = 0; i < this.status.length; i++) {
          //   let currStatusId = this.status[i].value;

          this.heatmaps[currStatusId].setMap(
            on && this.isStatusActive(currStatusId) ? this.map : null
          );
        }

        this.toggleMarkers(!on);
      }
    },
    createHeatmaps() {
      for (const [currStatusId, currStatus] of Object.entries(this.status)) {
        // for (let i = 0; i < this.status.length; i++) {
        //   let currStatus = this.status[i];
        let style = currStatus.style;

        this.heatmaps[currStatusId] = this.createHeatmap(currStatusId);
        this.changeGradient(
          this.heatmaps[currStatusId],
          style.light,
          style.dark
        );
      }
    },
    createHeatmap(statusId) {
      let points = this.marker.points[statusId];

      return new google.maps.visualization.HeatmapLayer({
        data: points,
        map: null,
        radius: 15
      });
    },
    changeGradient(heatmap, startColor, endColor) {
      heatmap.set(
        "gradient",
        ColorService.createGradient(startColor, endColor, 14)
      );
    },
    setMarker() {
      let gmarkers = {};
      let points = {};

      // eslint-disable-next-line no-unused-vars
      for (const [currStatusId, currStatus] of Object.entries(this.status)) {
        // for (let i = 0; i < this.status.length; i++) {
        //   let currStatus = this.status[i];

        gmarkers[currStatusId] = [];
        points[currStatusId] = [];
      }

      if (google) {
        let status = this.status;
        const createMarker = this.createMarker;
        const createInfowWindow = this.createInfoWindow;

        this.locations.forEach(function(node) {
          if (node.status) {
            const activeStatus = status[node.status.offer]; // Math.floor(Math.random() * status.length)

            if (activeStatus) {
              const point = new google.maps.LatLng(
                node.point.lat,
                node.point.lng
              );

              gmarkers[activeStatus.value].push(
                createMarker(
                  point,
                  node.address.street +
                    " " +
                    node.address.houseNumber +
                    node.address.houseNumberSuffix,
                  createInfowWindow(node),
                  activeStatus.style
                )
              );

              points[activeStatus.value].push({
                location: point,
                weight: 0.2 // node.info.amountHouseHolds * 10
              });
            }
          }
        });
      }

      this.marker = {
        gmarkers: gmarkers,
        points: points
      };
    }
  },
  async mounted() {
    try {
      google = await gmap.init();
      const geocoder = new google.maps.Geocoder();
      this.map = new google.maps.Map(this.$el, gmap.mapOptions);
      let map = this.map;
      this.zoom = this.map.getZoom();

      geocoder.geocode({ address: "Velbert" }, (results, status) => {
        if (status !== "OK" || !results[0]) {
          throw new Error(status);
        }

        map.setCenter(results[0].geometry.location);
        map.fitBounds(results[0].geometry.viewport);
      });

      const startRefreshMap = this.startRefreshMap;

      this.map.addListener("zoom_changed", function() {
        const zoom = map.getZoom();
        if (
          (zoom > zoomThreshold && window.currentZoom <= zoomThreshold) ||
          (zoom <= zoomThreshold && window.currentZoom > zoomThreshold)
        ) {
          clearTimeout(viewportChangedTimeout);

          viewportChangedTimeout = setTimeout(() => {
            startRefreshMap();
          }, 500);
        }

        window.currentZoom = zoom;
      });

      // this.map.addListener("center_changed", function() {
      //   const zoom = map.getZoom();
      //   if (zoom > 16) {
      //     clearTimeout(viewportChangedTimeout);
      //
      //     viewportChangedTimeout = setTimeout(() => {
      //       startRefreshMap();
      //     }, 1000);
      //   }
      // });
    } catch (error) {
      // console.error(error);
    }
  }
};
</script>

<style lang="scss">
@import "src/assets/sass/components/variables.bootstrap";

$marker-colors: (
  "yellow":#FFFF00,
  "orange":#FFA800,
  "red":#DA0B20,
  "green":#1BC5BD,
  "light-blue":#6993FF,
  "blue":#3333FF,
  "purple":#8950FC
);

$marker-colors-light: (
  "yellow":#FFFFE6,
  "orange":#FFF4DE,
  "red":#FCCFD3,
  "green":#BCF6F3,
  "light-blue":#E1E9FF,
  "blue":#B3B3FF,
  "purple":#EEE5FF
);

.custom-clustericon {
  background: var(--cluster-color-light);
  color: var(--cluster-color);
  border-radius: 100%;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.custom-clustericon::before {
  padding: 7px;
}

.custom-clustericon::after {
  padding: 14px;
}

@mixin dropshadow($color) {
  -webkit-box-shadow: 0 0 8px #{$color};
}

@each $key, $val in $marker-colors {
  .custom-clustericon-1--#{$key} {
    --cluster-color: #{$val};
    --cluster-color-light: #{map-get($marker-colors-light, $key)};
    @include dropshadow(map-get($marker-colors, $key));
  }

  .custom-clustericon-2--#{$key} {
    --cluster-color: #{$val};
    --cluster-color-light: #{map-get($marker-colors-light, $key)};
    @include dropshadow(map-get($marker-colors, $key));
  }

  .custom-clustericon-3--#{$key} {
    --cluster-color: #{$val};
    --cluster-color-light: #{map-get($marker-colors-light, $key)};
    @include dropshadow(map-get($marker-colors, $key));
  }

  .custom-clustericon-4--#{$key} {
    --cluster-color: #{$val};
    --cluster-color-light: #{map-get($marker-colors-light, $key)};
    @include dropshadow(map-get($marker-colors, $key));
  }
}
</style>
